body{
	margin:0px;
	display: flex;
}

#root{
  display:flex;
	width:100%;
	justify-content: center;
  flex-direction: column;
}
#header{
	display: flex;
	background-color:rgb(255, 255, 255);
	height:70px;
	width:100%;
	z-index:100;
  justify-content:flex-start;
  align-items: center;
}
#logo{
  cursor: default;
  white-space: nowrap;
  display: flex;
}
#logo img{
  /* width: 50px; */
  height: 50px;
  overflow-clip-margin: content-box;
  overflow: clip;
  padding:0px 20px;
}
#slogan{
  font-size: 24px;
  font-weight: bold;
  color: forestgreen;

}
iframe {
  height: 100vh;
  width: 100%;
  padding:0px;
  margin:0px;
  margin-top: -70px;
  border:none;
  position: relative;
}
.leaflet-container {
  width: 100%;
  height: calc(100vh-64px);
}